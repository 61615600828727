@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&family=Outfit:wght@400;500;700&display=swap");


.container,
p,
h1,
h2,
h3,
h4,
pre {
  font-family: "Outfit", sans-serif;
}
.container {
    width: 50% !important
}
.header {
    background-color: #152847;
    border-radius: 7px;
}
.form-label {
    font-weight: 500;
    color: #188bbc;
    font-size: medium;
}
/* 112c6d */
.form-label .text-muted {
    font-weight: 100;
    font-size:medium
}
hr.new {
    border-top: 2px dotted #152847;
}

.result {
    font-weight: bolder;
    color: #152847;
}

.result-des {
    background-color: aliceblue;
}

/* validation res text */
.val-response-text {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #c50f21;
    margin: 0;
}

@media (max-width: 1200px) {
    .container {
        width:100% !important;
        padding: 0px !important;
    }
    .btn {
       width: 50% !important; 
    }
   
}